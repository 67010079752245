import React, { useState } from "react";
import { useGlobal } from "reactn";

import SearchBar from "components/SearchBar";
import TierListLP from "./TierListLP";
import { ReactComponent as LolLogo2 } from "svg/lol-logo-filled.svg";
import { ReactComponent as WowLogo } from "svg/WoW-SelectorLogo.svg";
import { ReactComponent as ValorantLogo } from "svg/valorant-logo.svg";
import { ReactComponent as TFTLogo } from "svg/tft-logo.svg";
import GameButton from "../components/GameButton";
import HD2Promo from "../components/HD2Promo";
import SwarmPromo from "../components/SwarmPromo";
import TockersPromo from "../components/TockersPromo";

const LandingPageContainer = (props) => {
  const { className } = props;

  const [clientRegion] = useGlobal("clientRegion");
  const [region, setRegionValue] = useState(clientRegion);

  const games = [
    {
      logo: <LolLogo2 />,
      link: "/",
      id: "lol",
    },
    {
      logo: <ValorantLogo />,
      showNew: true,
      link: "/val",
      id: "val",
    },
    {
      logo: <WowLogo />,
      link: "/wow",
      showNew: true,
      id: "wow",
    },
    {
      logo: <TFTLogo />,
      link: "/tft/comps",
      id: "tft",
      showNew: true,
    },
  ];

  return (
    <div className={`landing-page-container_mobile ${className}`}>
      <div className="all-landing-page-content">
        <div className="landing-background">
          <div className="overlay" />
          <div className="background-image" />
        </div>
        <div className={"content-side-padding-region"}>
          <TockersPromo />
          <div className="landing-header">
            <img className="ugg-logo" src={"https://static.bigbrain.gg/assets/ugg/logo/ugg-logo.svg"} alt="U.GG" />
          </div>
          <SearchBar
            searchDefault={" "}
            placeholder={"Search a Champion"}
            searchBarId="mobile-search-landing"
            autoFocus={false}
            region={region}
          />
          <div className="game-switcher">
            {games.map((x, i) => (
              <GameButton key={i} {...x} />
            ))}
          </div>
          <TierListLP />
        </div>
      </div>
    </div>
  );
};

export default LandingPageContainer;
